import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`DesignCities: Montréal`}</h1>
    <h4>{`May 8–13, 2011 | Montréal, Québec, Canada`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBP/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAeu1negL/8QAFxAAAwEAAAAAAAAAAAAAAAAAABARAf/aAAgBAQABBQKEeL//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFRABAQAAAAAAAAAAAAAAAAAAIEH/2gAIAQEABj8Cq//EABwQAAEEAwEAAAAAAAAAAAAAABEAASFREDFxkf/aAAgBAQABPyEbKyhmX90gwvqZBOP/2gAMAwEAAgADAAAAEKQf/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBFR/9oACAEDAQE/EGo//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAMUFRYf/aAAgBAQABPxAErZtKTXp05AAAUgsGYqqOIHsQ9n//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "DesignCities: Montréal",
          "title": "DesignCities: Montréal",
          "src": "/static/0ef47a0e9ca40d495eb648077e1a4256/e5166/IMG_1755.jpg",
          "srcSet": ["/static/0ef47a0e9ca40d495eb648077e1a4256/f93b5/IMG_1755.jpg 300w", "/static/0ef47a0e9ca40d495eb648077e1a4256/b4294/IMG_1755.jpg 600w", "/static/0ef47a0e9ca40d495eb648077e1a4256/e5166/IMG_1755.jpg 1200w", "/static/0ef47a0e9ca40d495eb648077e1a4256/d9c39/IMG_1755.jpg 1800w", "/static/0ef47a0e9ca40d495eb648077e1a4256/df51d/IMG_1755.jpg 2400w", "/static/0ef47a0e9ca40d495eb648077e1a4256/44e20/IMG_1755.jpg 4416w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="https://archive.designinquiry.net/contributions/montreal-catalogue/" title="Montréal Catalogue by Gail Swanlund and Denise Gonzales Crisp" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/overview-the-structural-model-of-the-graphic-city-recursive-exegesis-the-graphic-code1-and-the-metro-polis-from-wikipedias-entry-of-freuds-the-ego-and/" title={"Overview: The Structural Model of the Graphic City: Recursive Exegesis: 'The Graphic-Code[1] and the Metro-Polis' from Wikipedia's entry of Freud's 'The Ego and the Id [2] by Joshua Singer'"} mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/fasterhigherstrongercitiusaltiusfortius/" title="Faster/Higher/Stronger/Citius/Altius/Fortuis) by Tim Vyner" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/souvenirs-of-montreal/" title="Souvenirs of Montréal by DesignInquiry" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/design-and-multi-modal-urban-transportation/" title="Design and Multi-modal Urban Transportation by Jennifer Nichols" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/root-maps-spatial-stories-in-the-city-of-design/" title="Root Maps: Spatial Stories in the City of Design by Alice Jarry, He Li, Jennifer Nichols, and Joshua Singer" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/instructions-for-the-design-of-a-city/" title="Instructions for the Design of a City by J. Cavelli" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/olyposition-2025/" title="Oly/position 2025 by Stuart Henley" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/seven-photographs-of-expo/" title="Seven Photographs of Expo by Anne Galperin" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/ad-hoc-co-design-the-micro-foodscape/" title="Ad hoc Co-design: The Micro-Foodscape by Josh Davidson" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/one-week-in-may-2011/" title="One week in May 2011 by Katrina Cutler-Lake" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/shadow-spaces-in-a-design-city/" title="Shadow Spaces in a Design City by Bobby Campbell" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/more-than-human/" title="More than Human by Cecelia Chen" mdxType="Outcomeitem" />
    <Outcomeitem link="https://www.youtube.com/watch?v=U8F38aew-tw" title="Design City by Margo Halverson" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/cities-of-myth-nostalgia-and-the-end-visions-of-urbanity/" title="Cities of Myth, Nostalgia and the End: Visions of Urbanity by Bobby Campbell" mdxType="Outcomeitem" />
    </Sidebar>
    <p><strong parentName="p">{`In 2006, Montréal, Québec, Canada was designated by UNESCO as the first and only North American City Of Design. From the cultural legacy of Expo 67 and the 1976 Olympics, to numerous examples of contemporary creative work including art, fashion, performing arts and architecture, Montréal is clearly a creative hotspot. Its languages, its prejudices, its line-ups, la mode: all of these elements inform Montréal’s global disposition and local temperament. So, are design qualities somehow inherent in Montréal’s topographies, its citizens, its outputs? What makes a “City of Design” more relevant or significant than any other similarly vaunted location? Are these qualities designed by designers or do they evolve out of the way people use the city? Can we identify the characteristics of the dialog between the city and its users? (Can we emphasize the dialog by adding something or taking something away?)`}</strong></p>
    <p><strong parentName="p">{`The notion that design practice, and the presence of designers, can alter the trajectory of a city for the better is an assumption that beseeches exploration and testing. Meeting Montréal’s flora and fauna—getting used to its habits and communicating with its inhabitants—was at the core of DesignInquiry-slash-Montréal. We were there as designers, exploring this design town.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      